@use '@/app/queries' as *;
@use '@/shared/styles/fixed' as *;
@use '@/shared/ui/typography/sizes' as *;
@use '@/shared/ui/button/adaptive' as *;

.container {
  width: 38px;
  @include min-428-break {
    width: 48px;
  }
}

.trigger-button {
  @include buttonSizes('38', '48', '48', '48', '48', '48');
}

.sheetContainer {
  height: calc(100% - env(safe-area-inset-top)) !important;
  border-radius: 0 !important;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  box-shadow: var(--elevation-1);
  @include min-428-break {
    padding: 14px;
  }
  @include min-744-break {
    padding: 14px 28px;
  }
  &-title {
    @include typography-m;
    @include min-428-break {
      @include typography-l;
    }
  }
  &-right-slot {
    display: flex;
    align-items: center;
    gap: 10px;
    @include min-428-break {
      gap: 12px;
    }
  }
}

.backButton {
  padding: 8px 10px;
  border-radius: var(--cornerradius10) !important;
  @include singleFixedSize(42px);
  span {
    @include singleFixedSize(18px);
  }
  @include min-428-break {
    border-radius: var(--cornerradius12) !important;
    padding: 12.5px 13px;
    span {
      @include singleFixedSize(22px);
    }
    @include singleFixedSize(52px);
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--global-grey300);

  @include min-428-break {
    gap: 14px;
  }
}

.section {
  width: 100%;
  border-radius: 12px;
  background-color: var(--global-white);
  padding: 12px 14px;

  @include min-428-break {
    border-radius: 14px;
    padding: 14px;
  }
  @include min-744-break {
    border-radius: 16px;
    padding: 14px 28px;
  }
}

.first-section {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.slider-container {
  gap: 12px;
  @include min-428-break {
    gap: 14px;
  }
  @include min-744-break {
    gap: 16px;
  }
}

.bottom-buttons {
  width: 100%;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-shadow: var(--elevation-2);
  @include min-428-break {
    padding: 14px;
    gap: 10px;
  }
  @include min-744-break {
    padding: 16px 28px;
  }
}

.bottom-button {
  @include buttonSizes('38', '46', '52', '52', '52', '52');
}
