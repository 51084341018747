@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@content' as *;

.filter-section {
  @include mainContentStyles;
  width: 100%;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  top: 0;
  z-index: 101;

  @include min-428-break {
    padding: 14px;
    gap: 12px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }
}

.main_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 10px;
  height: 42px;
  width: 100%;
  border: 2px solid var(--cst-inputauth-outline-default);
  border-radius: var(--cornerradius10);
  gap: 8px;

  p {
    @include typography-s;
  }

  @include min-428-break {
    height: 52px;
    padding: 0 12px;
    gap: 12px;
    border-radius: var(--cornerradius12);

    p {
      @include typography-base;
    }
  }

  &:focus-within {
    border: 2px solid var(--cst-inputauth-outline-intype);
  }
}

.input_wrapper {
  flex: 1;
  height: 95%;
}

.input_field {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  font-family: Inter, sans-serif;
  color: var(--cst-input-search-text-typing);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding-inline: 0;
  letter-spacing: 0;
  text-align: left;
  background-color: white;

  @include min-428-break {
    font-size: 14px;
    line-height: 21px;
  }
}

.filter-bottom-slot {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.search_icon {
  @include singleFixedSize(20px);

  @include min-428-break {
    @include singleFixedSize(24px);
  }
}
